// Importando páginas de forma assíncrona
const Helper = () => import("./helper/Helper")
const HomeExterno = () => import("./pages/HomeExterno")
const HomeInterno = () => import("./pages/HomeInterno")
const LojaVirtual = () => import("./pages/LojaVirtual")
const Live = () => import("./pages/Live")
const NotFound = () => import("./pages/NotFound")
const SSOAuth = () => import("./pages/SSOAuth")
const Login = () => import("./pages/Login")
const CadastroAlunoExterno = () => import("./pages/CadastroAlunoExterno")
const LandingPageRedirect = () => import("./pages/LandingPageRedirect")

// Criando rotas
export const routes = [
//#region Telas independentes
    // Help  
    {
      name: "Helper",
      path: "/TdOFF6rt7m8P8lx6G5bL",
      component: Helper,
      props: true,
    },
    {
      name: "SSOAuth",
      path: "/ssoAuth",
      component: SSOAuth,
      props: true,
    },  
    {
      name: "HomeExternoPrincipal",
      path: "/",
      component: HomeExterno,
      props: true,
    },
  
    {
      name: "HomeExternoEmpresas",
      path: "/para-empresas",
      component: HomeExterno,
      props: true,
    },
  
    {
      name: "HomeExternoEscolas",
      path: "/para-escolas",
      component: HomeExterno,
      props: true,
    },
    {
      name: "HomeExternoDeveloper",
      path: "/developer",
      component: HomeExterno,
      props: true,
    },
    {
      name: "LojaVirtualVitrine",
      path: "/loja-virtual/:id_plataforma",
      component: LojaVirtual,
      props: true,
    },
  
    {
      name: "LojaVirtualCarrinho",
      path: "/loja-virtual/:id_plataforma/carrinho",
      component: LojaVirtual,
      props: true,
    },
  
    {
      name: "LojaVirtualCurso",
      path: "/loja-virtual/:id_plataforma/curso/:id_curso",
      component: LojaVirtual,
      props: true,
    },
  
    {
      name: "LojaVirtualCheckout",
      path: "/loja-virtual/:id_plataforma/checkout",
      component: LojaVirtual,
      props: true,
    },  
    {
      name: "LojaVirtualCheckoutFinal",
      path: "/loja-virtual/:id_plataforma/checkout_end",
      component: LojaVirtual,
      props: true,
    },  
    {
      name: "HomeExternoVideos",
      path: "/videos",
      component: HomeExterno,
      props: true,
    },  
    {
      name: "HomeExternoConvite",
      path: "/convite",
      component: HomeExterno,
      props: true,
    },  
    {
      name: "Live",
      path: "/live/:id_live",
      component: Live,
      props: true,
    }, 
    {
      name: "HomeInternoMinhasPlataformas",
      path: "/minhas-plataformas",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoAdminFast",
      path: "/adminfast",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoCadastro",
      path: "/cadastro",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoCadastroPlataforma",
      path: "/cadastro-plataforma",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoPlataforma",
      path: "/plataforma/:id_plataforma",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoMeuPerfil",
      path: "/plataforma/:id_plataforma/meu-perfil",
      component: HomeInterno,
      props: true,
    },  
    {
      name: "HomeInternoComissao",
      path: "/comissao",
      component: HomeInterno,
      props: true,
    },
    // Cadastro Externo de aluno
    {
      name: "CadastroAlunoExterno",
      path: "/cadastro-aluno",
      component: CadastroAlunoExterno,
      props: true,
    },
    {
      name: "LandingPageRedirect",
      path: "/comprar-curso-desenvolvimento",
      component: LandingPageRedirect,
      props: true,
    },
//#endregion 
//#region Perfil admin
    {
      name: "HomeInternoAdmin",
      path: "/plataforma/:id_plataforma/admin",
      component: HomeInterno,
      props: true,
      children: [
        /*
        {
          name: "HomeInternoAdminPagProvisoriaAustran.vue",
          path: "lista",
          component: HomeInterno,
          props: true,
        },
        */
        {
          name: "HomeInternoAdminDashboard",
          path: "dashboard",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminSuporte",
          path: "suporte",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminMeusCursos",
          path: "meus-cursos",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminCurso",
          path: "curso/:id_curso",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminCursoAulas",
          path: "curso/:id_curso/aulas",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminCursoAulaAtividade",
          path: "curso/:id_curso/aulas/:id_aula/atividade/:id_atividade",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminUsuarios",
          path: "usuarios",
          component: HomeInterno,
          props: true,
        },

        {
          name: "HomeInternoAdminIntegracoes",
          path: "integracoes",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminAlunos",
          path: "alunos",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminProfessores",
          path: "professores",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminAdministradores",
          path: "administradores",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminSecretarios",
          path: "secretarios",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminTesoureiros",
          path: "tesoureiros",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminResponsaveis",
          path: "responsaveis",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarPlataforma",
          path: "personalizar-plataforma",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarCertificados",
          path: "personalizar-certificados",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarRanking",
          path: "personalizar-ranking",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminTutoriais",
          path: "tutoriais",
          component: HomeInterno,
          props: true,
        },
  
        {
          name: "HomeInternoAdminPersonalizarMedalhas",
          path: "personalizar-medalhas",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarEmails",
          path: "personalizar-emails",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarDisciplinas",
          path: "personalizar-disciplinas",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarProvas",
          path: "provas",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminPersonalizarQuestoes",
          path: "provas/:id_prova",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminMatrizCapacitacao",
          path: "matriz-capacitacao",
          component: HomeInterno,
          props: true,
        },

        {
          name: "HomeInternoAdminJobs",
          path: "jobs",
          component: HomeInterno,
          props: true,
        },
      
        {
          name: "HomeInternoAdminMural",
          path: "mural",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminLives",
          path: "lives",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminFinanceiro",
          path: "financeiro",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminIntegracoesAssinaturaDocumentos",
          path: "assinatura-documentos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAdminIntegracoesGatewaysPagamento",
          path: "gateways-pagamento",
          component: HomeInterno,
          props: true,
        },
      ]
    },  
//#endregion
//#region Perfil secretaria
    {
      name: "HomeInternoSecretaria",
      path: "/plataforma/:id_plataforma/secretaria",
      component: HomeInterno,
      props: true,
      children: [
        {
          name: "HomeInternoSecretariaDashboard",
          path: "dashboard",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaDocumentos",
          path: "documentos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaContratos",
          path: "contratos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCategoriasDeAutenticacao",
          path: "categorias-autenticacao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaTemplateComposicao",
          path: "template-composicao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaPlanos",
          path: "planos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaRequerimentos",
          path: "requerimentos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaOcorrencias",
          path: "ocorrencias",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaBaseCurricular",
          path: "base-curricular",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaBaseCurricularComponentes",
          path: "base-curricular/:id_base_curricular/componentes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaBaseBibliografica",
          path: "base-bibliografica",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCursos",
          path: "cursos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCurso",
          path: "curso/:id_secretaria_curso",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCursoPrograma",
          path: "curso/:id_secretaria_curso/programa/:id_secretaria_curso_programa",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCertificadores",
          path: "certificadores",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaTurmas",
          path: "turmas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaTurma",
          path: "turma/:id_turma",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaTurmaPrograma",
          path: "turma/:id_turma/programa/:id_programa_turma",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaTurmaAlunos",
          path: "turma/:id_turma/alunos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaIngressos",
          path: "ingressos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaIngressoRequisito",
          path: "ingresso/:id_trilha_ingresso/requisito/:id_registro",
          component: HomeInterno,
          props: true,
        },      
        {
          name: "HomeInternoSecretariaUsuarios",
          path: "usuarios",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAlunos",
          path: "alunos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaProfessores",
          path: "professores",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaSolicitacoesMatricula",
          path: "solicitacoes-matricula",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAluno",
          path: "aluno/:id_pessoa_aluno",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaMatricular",
          path: "matricula/:id_pessoa_aluno",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaProfessor",
          path: "professor/:id_pessoa",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaSala",
          path: "salas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaResultadoAluno",
          path: "resultados",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaSaidaAluno",
          path: "saidas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaJustificativaFalta",
          path: "justificativas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaCausaDispensa",
          path: "dispensas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaFeriado",
          path: "feriados",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaUnidadesCurriculares",
          path: "unidades-curriculares",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaUnidadeCurricular",
          path: "unidade-curricular/:id_curso",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaUnidadeCurricularAulas",
          path: "unidade-curricular/:id_curso/aulas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaUnidadeCurricularAulaAtividade",
          path: "unidade-curricular/:id_curso/aulas/:id_aula/atividade/:id_atividade",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaDisciplinas",
          path: "disciplinas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAvaliacoes",
          path: "avaliacoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAvaliacoesModelos",
          path: "avaliacoes-modelos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAvaliacaoModelo",
          path: "avaliacao-modelo/:id_avaliacao_modelo",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAvaliacaoModeloUc",
          path: "avaliacao-modelo/:id_avaliacao_modelo/uc/:id_secretaria_avaliacao_uc",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaKanban",
          path: "kanban",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaRelatoriosAvaliacoes",
          path: "relatorios-avaliacoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaRelatoriosRequisicoesAlunos",
          path: "relatorios-requisicoes-alunos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoSecretariaAssinaturaDeDocumentos",
          path: "assinatura-de-documentos",
          component: HomeInterno,
          props: true,
        },
      ]
    }, 
//#endregion 
//#region Perfil tesouraria
    {
      name: "HomeInternoTesouraria",
      path: "/plataforma/:id_plataforma/tesouraria",
      component: HomeInterno,
      props: true,
      children: [
        {
          name: "HomeInternoTesourariaDashboard",
          path: "dashboard",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaBoletos",
          path: "emissao-boletos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaItensCobranca",
          path: "itens-cobranca",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaComissoes",
          path: "comissoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaGeracaoCobrancas",
          path: "geracaocobrancas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaGestaoComissoes",
          path: "gestao-comissoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaGerencianet",
          path: "gerencianet",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRecebiveis",
          path: "gestao-recebiveis",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaPosicaoContasReceber",
          path: "posicao-contas-receber",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaFluxoCaixa",
          path: "fluxo-caixa",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRenegociacao",
          path: "renegociacao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaControleContas",
          path: "controle-contas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaContasPagar",
          path: "contas-pagar",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaPessoas",
          path: "pessoas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaFuncoes",
          path: "funcoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaCadastroEmpresa",
          path: "cadastro-empresa",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaNotasFiscais",
          path: "notas-fiscais",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosContratoAlunos",
          path: "relatorios-contratos-alunos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosContasReceber",
          path: "relatorios-contas-receber",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosMatriculas",
          path: "relatorios-matriculas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosAlunoPosicao",
          path: "relatorios-aluno-posicao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosRegistroInadimplencia",
          path: "relatorios-registro-inadimplencia",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoTesourariaRelatoriosCobrancaWhatsapp",
          path: "relatorios-cobranca-whatsapp",
          component: HomeInterno,
          props: true,
        },
      ]
    },  
//#endregion 
//#region Perfil aluno
    {
      name: "HomeInternoAluno",
      path: "/plataforma/:id_plataforma/aluno",
      component: HomeInterno,
      props: true,
      children: [
        {
          name: "HomeInternoAlunoAtendimento",
          path: "atendimento",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMeusCursos",
          path: "meus-cursos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoTurmas",
          path: "turmas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoTurma",
          path: "turma/:id_turma",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoTurmaPrograma",
          path: "turma/:id_turma/programa/:id_programa_turma",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoTurmaProgramaAvaliacao",
          path: "turma/:id_turma/programa/:id_programa_turma/avaliacao/:id_secretaria_avaliacao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoCursoDetalhes",
          path: "meus-cursos/curso/:id_curso",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoCursoAula",
          path: "meus-cursos/curso/:id_curso/aula/:id_aula",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoSecretariaExtratoParcelas",
          path: "extrato-parcelas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMeusBoletos",
          path: "meus-boletos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMural",
          path: "mural",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMinhasCapacitacoes",
          path: "minhas-capacitacoes",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoLives",
          path: "lives",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMeusCadernos",
          path: "meus-cadernos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMeusCertificados",
          path: "meus-certificados",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoMeusRequerimentos",
          path: "meus-requerimentos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoOcorrencias",
          path: "minhas-ocorrencias",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoSecretariaDocumentos",
          path: "meus-documentos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoPerguntas",
          path: "perguntas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoAulasParticulares",
          path: "aulas-particulares",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoRevisaoRedacao",
          path: "revisao-redacao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoSecretaria",
          path: "secretaria",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoTutoriais",
          path: "tutoriais",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoAlunoPatioVirtual",
          path: "patio-virtual",
          component: HomeInterno,
          props: true,
        },
      ]
    },  
//#endregion 
//#region Perfil professor
    {
      name: "HomeInternoProfessor",
      path: "/plataforma/:id_plataforma/professor",
      component: HomeInterno,
      props: true,
      children: [
        {
          name: "HomeInternoProfessorDashboard",
          path: "dashboard",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorPerguntas",
          path: "perguntas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorAulasParticulares",
          path: "aulas-particulares",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorRevisoesRedacao",
          path: "revisoes-redacao",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorTurmas",
          path: "turmas",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorAgenda",
          path: "agenda",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorMeusCursos",
          path: "meus-cursos",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorCursoAulas",
          path: "curso/:id_curso/aulas",
          component: HomeInterno,
          props: true,
        },    
        {
          name: "HomeInternoProfessorCursoAulaAtividade",
          path: "curso/:id_curso/aulas/:id_aula/atividade/:id_atividade",
          component: HomeInterno,
          props: true,
        },
        {
          name: "HomeInternoProfessorLives",
          path: "lives",
          component: HomeInterno,
          props: true,
        },
      ]
    },
//#endregion 
//#region Logins externos
    {
      name: "LoginAluno",
      path: "/aluno",
      component: Login,
      props: true,
    },
    {
      name: "LoginProfessor",
      path: "/professor",
      component: Login,
      props: true,
    },
    {
      name: "LoginAdministrador",
      path: "/administrador",
      component: Login,
      props: true,
    },
    {
      name: "LoginTesouraria",
      path: "/tesouraria",
      component: Login,
      props: true,
    },
    {
      name: "LoginSecretaria",
      path: "/secretaria",
      component: Login,
      props: true,
    },
    {
      name: "LoginResponsavel",
      path: "/responsavel",
      component: Login,
      props: true,
    },
//#endregion 
//#region Telas de erros
    {
      name: "NotFound",
      path: "*",
      component: NotFound,
      props: true,
    },    
//#endregion
];